
.summary-label{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    letter-spacing: 0.3rem;
    opacity: 0.8;
}
.summary-label-down{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    letter-spacing: 0.3rem;
    opacity: 0.8;
}
.summary-text{
    margin: 2.5rem;
    text-align: left;
    font-size: 2.3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 2.5rem;

}
@media only screen and (min-width: 1200px) {
    .summary-label-down{
        display: none;
        font-size: 1.2rem;
        color: #e6e7e8;
        padding-bottom: 0.7rem;
        border-bottom:1px solid #e6e7e8;
        text-align: left;
        font-family: 'neue55Rg';
        font-weight: normal;
        letter-spacing: 0.3rem;
        opacity: 0.8;
    }
    .summary-text{
        margin: 2.5rem;
        text-align: left;
        font-size: 2.3rem;
        color: #e6e7e8;
        font-family: 'neue45Lt', serif;
        font-weight: bold;
        line-height: 2.5rem;
    
    }
  }
