.date-time{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    margin-top: 4.5rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    opacity: 0.8;
    letter-spacing: 0.3rem;

}

  .date-time-text{
    margin: 2.5rem;
    text-align: left;
    font-size: 3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 3rem;
}

@media screen and (orientation: landscape) {
  .date-time{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    margin-top: 0rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    opacity: 0.8;
    letter-spacing: 0.3rem;

}

  .date-time-text{
    margin: 2.5rem;
    text-align: left;
    font-size: 3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 3rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .date-time-text{
    margin: 2rem;
    text-align: left;
    font-size: 3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 2.5rem;
  }
}
