body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'neue65Md';
  src: local('neue'), url( https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-65Md.ttf) format('truetype');
}

@font-face {
  font-family: 'neue55Rg';
  src: local('neue'), url( https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-55Rg.ttf) format('truetype');
}

@font-face {
  font-family: 'neue45Lt';
  src: local('neue'), url( https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-45Lt.ttf) format('truetype');
}
@font-face {
  font-family: 'lubalin';
  src: url( https://cdn.talentgarden.com/fonts/lubalin/lubalingraphstd-demi-webfont.ttf) format('truetype');
}

