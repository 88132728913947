.App {
  background-size: auto;
  background-color:  #1e1e1e;
  

}
html {
  background-size: 100%;
  background-color:  #1e1e1e;
}


.alert-text{
  margin: 2.5rem;
  text-align: left;
  font-size: 4rem;
  color: #e6e7e8;
  font-family: 'lubalin', normal;
  font-weight: bolder;
  line-height: 4rem;
}

.qr-code{
  margin: 2.5rem;
}


.fadeOut{
  opacity:0;
  transition: width 0.8s 0.8s, height 0.8s 0.8s, opacity 0.8s;

}
.fadeIn{
  opacity:1;
  transition: width 1s, height 1s, opacity 1s;

}

@media screen and (orientation: landscape) {
  .containerFix{
    max-width: 1920px !important;
  }
  
}
