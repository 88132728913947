
.logo{
    width: 100%;
    height: 540px;
    
}

@media screen and (orientation: portrait) {
    
}  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo{
        width: 100%;
        margin-left: unset;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .logo{
        width: 100%;
        margin-left: unset;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .logo{
        width: 100%;
        margin-left: unset;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .logo{
        width: 118.4%;
        margin-left: -9.2%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .logo{
        width: 100%;
        height: 100%;
        margin-left: unset;
    }
}

@media only screen and (min-width: 1600px) {
    .logo{
        width: 100%;
        height: 100%;
        margin-left: unset;
    }
}


