
  
.title {
        margin: 1rem;
        font-size: 4rem;
        font-family: 'lubalin', normal;
        color: #e6e7e8;
        line-height: 4rem;
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
      .title {
        margin: 1rem;
        font-size: 3rem;
        font-family: 'lubalin', normal;
        color: #e6e7e8;
        line-height: 4rem;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    }
}

@media only screen and (min-width: 1600px) {
  .title {
    margin: 1rem;
    font-size: 3rem;
    font-family: 'lubalin', normal;
    color: #e6e7e8;
    line-height: 4rem;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
}

