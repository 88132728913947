.location{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    margin-top: 4.5rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    opacity: 0.8;
    letter-spacing: 0.3rem;
  
}
  .location-text{
    margin: 2.5rem;
    text-align: left;
    font-size: 3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 3rem;
}

@media screen and (orientation: landscape) {
  .location{
    font-size: 1.2rem;
    color: #e6e7e8;
    padding-bottom: 0.7rem;
    margin-top: 0rem;
    border-bottom:1px solid #e6e7e8;
    text-align: left;
    font-family: 'neue55Rg';
    font-weight: normal;
    opacity: 0.8;
    letter-spacing: 0.3rem;
  
}
  .location-text{
    margin: 2.5rem;
    text-align: left;
    font-size: 3rem;
    color: #e6e7e8;
    font-family: 'neue45Lt', serif;
    font-weight: bold;
    line-height: 3rem;
}
}
